import React from "react";
import Page404 from "../../components/Page404";
import Layout from "../../components/Layout";
import MyHelmet from "../../context/MyHelmet";

export default () => {
  return (
    <div>
      <MyHelmet page="home" language="dk" />
      <Layout>
        <Page404 />
      </Layout>
    </div>
  );
};
